import React, { useRef, useState, useEffect } from "react"
import { graphql, Link } from "gatsby"

import styles from "./index.module.scss"
import Arrow from "../../images/arrow-black.svg"
import PreFooter from "../../components/athena/preFooter"
import Layout, { athenaTheme } from "../../components/layout"

import IssueImg from "../../images/athena-04-resilience/index/small-shape.gif"
import MobileImg from "../../images/athena-04-resilience/index/more-articles-mobile.png"
import MobileImgSVG from "../../images/athena-04-resilience/index/more-articles-mobile.svg"

import articleImage01 from "../../images/athena-04-resilience/01-joy-can-lead-us/article-image.png"
import articleShape01 from "../../images/athena-04-resilience/01-joy-can-lead-us/shape.png"
import articleImage02 from "../../images/athena-04-resilience/02-engestation/article-image.jpg"
import articleShape02 from "../../images/athena-04-resilience/02-engestation/shape.png"
import articleImage03 from "../../images/athena-04-resilience/03-vocab-of-resilience/article-image.jpeg"
import articleShape03 from "../../images/athena-04-resilience/03-vocab-of-resilience/shape.png"
import articleImage04 from "../../images/athena-04-resilience/04-sounds/article-image.png"
import articleShape04 from "../../images/athena-04-resilience/04-sounds/shape.png"
import articleImage05 from "../../images/athena-04-resilience/05-flourishing/article-image.jpg"
import articleShape05 from "../../images/athena-04-resilience/05-flourishing/shape.png"

import articleImage06 from "../../images/athena-04-resilience/06-rethinking/article-image.jpg"
import articleShape06 from "../../images/athena-04-resilience/06-rethinking/shape.png"

import endlesssBkg from "../../images/athena-04-resilience/index/endlesssbgk.jpg"
import endlesssShape from "../../images/athena-04-resilience/index/endlesssshape.png"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
  headerPosition: styles.position,
}

const AthenaPage = ({ data }) => {
  const page = data.craft.page
  const seo = page.seomatic
  const issue = page.featuredIssue[0]
  const issueNum = 1 + data.craft.issues.findIndex(item => item.id === issue.id)

  const articles = data.craft.articles.filter(
    article => article.issue[0].id === issue.id
  )
  const articlesRef = useRef(null)
  const issueTextRef = useRef(null)

  const articleImages = [
    articleImage01,
    articleImage02,
    articleImage03,
    articleImage04,
    articleImage05,
    articleImage06,
  ]
  const articleShapes = [
    articleShape01,
    articleShape02,
    articleShape03,
    articleShape04,
    articleShape05,
    articleShape06,
  ]

  let [hoveredSentence, setHoveredSentence] = useState(true)
  const [hoverFeatured, setHoverFeatured] = useState(false)

  useEffect(() => {
    setHoveredSentence(1)
    let timeoutId = setTimeout(() => {
      setHoveredSentence(2)
      timeoutId = setTimeout(() => {
        setHoveredSentence(3)
        timeoutId = setTimeout(() => {
          setHoveredSentence(4)
        }, 1000)
      }, 800)
    }, 600)
    return () => clearTimeout(timeoutId)
  }, [])

  let group = index => ({
    style: {
      transition: "opacity .6s ease-in-out",
      opacity: hoveredSentence >= index ? 1 : 0.0125,
    },
  })

  useEffect(() => {
    const joyArt = document.getElementById("article-0")
    const engArt = document.getElementById("article-1")
    const vocabArt = document.getElementById("article-2")
    const soundArt = document.getElementById("article-3")
    const flourishArt = document.getElementById("article-4")
    const rethinkArt = document.getElementById("article-5")
    if (joyArt !== null) {
      joyArt.onmouseover = function() {
        document.getElementById("article-1").style.display = "none"
        document.getElementById("article-2").style.display = "none"
        document.getElementById("article-3").style.display = "none"
        document.getElementById("article-4").style.display = "none"
        document.getElementById("article-5").style.display = "none"
      }
      joyArt.onmouseout = function() {
        document.getElementById("article-1").style.display = "block"
        document.getElementById("article-2").style.display = "block"
        document.getElementById("article-3").style.display = "block"
        document.getElementById("article-4").style.display = "block"
        document.getElementById("article-5").style.display = "block"
      }
    }
    if (engArt !== null) {
      engArt.onmouseover = function() {
        document.getElementById("article-0").style.display = "none"
        document.getElementById("article-2").style.display = "none"
        document.getElementById("article-3").style.display = "none"
        document.getElementById("article-4").style.display = "none"
        document.getElementById("article-5").style.display = "none"
      }
      engArt.onmouseout = function() {
        document.getElementById("article-0").style.display = "block"
        document.getElementById("article-2").style.display = "block"
        document.getElementById("article-3").style.display = "block"
        document.getElementById("article-4").style.display = "block"
        document.getElementById("article-5").style.display = "block"
      }
    }
    if (vocabArt !== null) {
      vocabArt.onmouseover = function() {
        document.getElementById("article-0").style.display = "none"
        document.getElementById("article-1").style.display = "none"
        document.getElementById("article-3").style.display = "none"
        document.getElementById("article-4").style.display = "none"
        document.getElementById("article-5").style.display = "none"
      }
      vocabArt.onmouseout = function() {
        document.getElementById("article-0").style.display = "block"
        document.getElementById("article-1").style.display = "block"
        document.getElementById("article-3").style.display = "block"
        document.getElementById("article-4").style.display = "block"
        document.getElementById("article-5").style.display = "block"
      }
    }
    if (soundArt !== null) {
      soundArt.onmouseover = function() {
        document.getElementById("article-0").style.display = "none"
        document.getElementById("article-1").style.display = "none"
        document.getElementById("article-2").style.display = "none"
        document.getElementById("article-4").style.display = "none"
        document.getElementById("article-5").style.display = "none"
      }
      soundArt.onmouseout = function() {
        document.getElementById("article-0").style.display = "block"
        document.getElementById("article-1").style.display = "block"
        document.getElementById("article-2").style.display = "block"
        document.getElementById("article-4").style.display = "block"
        document.getElementById("article-5").style.display = "block"
      }
    }
    if (flourishArt !== null) {
      flourishArt.onmouseover = function() {
        document.getElementById("article-0").style.display = "none"
        document.getElementById("article-1").style.display = "none"
        document.getElementById("article-2").style.display = "none"
        document.getElementById("article-3").style.display = "none"
        document.getElementById("article-5").style.display = "none"
      }
      flourishArt.onmouseout = function() {
        document.getElementById("article-0").style.display = "block"
        document.getElementById("article-1").style.display = "block"
        document.getElementById("article-2").style.display = "block"
        document.getElementById("article-3").style.display = "block"
        document.getElementById("article-5").style.display = "block"
      }
    }
    if (rethinkArt !== null) {
      rethinkArt.onmouseover = function() {
        document.getElementById("article-0").style.display = "none"
        document.getElementById("article-1").style.display = "none"
        document.getElementById("article-2").style.display = "none"
        document.getElementById("article-3").style.display = "none"
        document.getElementById("article-4").style.display = "none"
      }
      rethinkArt.onmouseout = function() {
        document.getElementById("article-0").style.display = "block"
        document.getElementById("article-1").style.display = "block"
        document.getElementById("article-2").style.display = "block"
        document.getElementById("article-3").style.display = "block"
        document.getElementById("article-4").style.display = "block"
      }
    }
  }, [])

  return (
    <Layout seo={seo} mode="athena" theme={theme} {...group(1)}>
      <div className={`${styles.page}`}>
        <div className={styles.pageContent}>
          <h1 className="screen-reader-only">Athena Jones</h1>

          <div className={styles.hero} {...group(2)}>
            <div className={styles.header}>
              <p className={`${styles.white} a-h-5 text-center`}>
                Issue {issueNum.toString().padStart(2, "0")}
              </p>
              <h1 className={styles.pageTitle} {...group(3)}>
                <em>E</em>x<b>p</b>lor<b>in</b>g
              </h1>
              <h1
                {...group(3)}
                className={`${styles.pageTitle} ${styles.pageTitleJoyMobile}`}
              >
                J<em>o</em>y
              </h1>
              <div className={styles.oval}>
                <h2 {...group(4)}>
                  <b>Rei</b>m<b>a</b>g<b>in</b>
                  <em>i</em>
                  <b>n</b>g <em>R</em>
                  <b>e</b>s<b>ili</b>e<b>n</b>c<b>e</b>
                </h2>

                <video
                  className={styles.video}
                  autoplay="true"
                  loop
                  muted
                  playsinline
                >
                  <source
                    src="https://zj-v6.imgix.net/athena-content/I04-Exploring-Joy/hero-HEVC.mov"
                    type='video/mp4; codecs="hvc1"'
                  />
                  <source
                    src="https://zj-v6.imgix.net/athena-content/I04-Exploring-Joy/hero.webm"
                    type="video/webm"
                  />
                </video>
              </div>
              <h1
                {...group(3)}
                className={`${styles.pageTitle} ${styles.pageTitleJoy}`}
              >
                J<em>o</em>y
              </h1>
            </div>
          </div>
          <div className={styles.issueBody}>
            <div className={styles.issueText} ref={issueTextRef}>
              <div className="wrapper">
                <div className="columns is-desktop">
                  <div className="column is-12-desktop">
                    <h3 className={styles.h3}>
                      <span>R</span>esilien<b>c</b>e is <span>o</span>nly a
                      virtu<b>e</b> <b>w</b>hen <b>i</b>t hel<b>p</b>s u<b>s</b>{" "}
                      gr<span>o</span>w. In<b>s</b>tea<b>d</b>, it{" "}
                      <span>o</span>ften <b>b</b>r<b>e</b>aks <b>u</b>s dow
                      <b>n</b>.
                    </h3>
                  </div>
                </div>
              </div>
              <div className="wrapper">
                <div className="columns is-desktop is-tablet is-centered">
                  <div className="column is-12-tablet is-10-desktop is-8-fullhd">
                    <div className="user-content-athena">
                      <p>
                        From crumbling national infrastructure and employee
                        mental health to mounting climate and identity-based
                        trauma, we have enough breaking us down already. Yet our
                        most celebrated acts of resilience — gritting our teeth
                        to perform under stress — rely on behaviors that put our
                        human needs last and erode our wellbeing.
                      </p>
                      <p>
                        When resilience embodies a selfless duty to produce no
                        matter the cost, the grind becomes a reality, slowly,
                        relentlessly wearing us away.
                      </p>
                      <p>
                        What if we could foster a kind of resilience that
                        increases our agency in pursuing things that make us
                        more human and not less?
                      </p>
                    </div>
                    <div className={styles.paralaxContainer}>
                      <img className={styles.para} src={IssueImg} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrapper">
                <h3 className={styles.h3}>
                  <b>W</b>ha<b>t</b> if choo<b>s</b>i<b>n</b>g j<span>o</span>y{" "}
                  <b>a</b>s <span>o</span>ur p<b>ri</b>ma<b>ry</b> du<b>t</b>y{" "}
                  <b>m</b>ad<b>e</b> u<b>s</b> m<span>o</span>re resi<b>l</b>i
                  <b>en</b>t?
                </h3>
              </div>
              <div className="wrapper">
                <div className="columns is-desktop is-tablet is-centered">
                  <div className="column is-12-tablet is-10-desktop is-8-fullhd">
                    <div className="user-content-athena">
                      <p>
                        What if prioritizing a more sustainable, joyful
                        resilience was both a personal and cultural obligation?
                      </p>
                      <p>
                        The human capacity for joy is one of our most plentiful,
                        yet underutilized resources. When we pursue joy we seek
                        freedom and abundance. We play, we imagine, we
                        experiment, we learn, and we grow — opening a pathway
                        for adaptive change. Joy brings out the best in us. When
                        we are joyful, we aren't just happy or content, we are
                        radiant, and our joy spreads to others.
                      </p>
                      <p>
                        It's time to decouple our concept of growth from our
                        idea of productivity and the expectation of endurance
                        through a toxic status quo. Humans grow in all
                        directions, even backwards.
                      </p>
                      <p>
                        When people can express their humanity, rather than just
                        their productivity, they innovate, celebrate each other,
                        and build the confidence to face whatever comes next on
                        their own terms.
                      </p>
                      <p>
                        We can make this happen if we ask the right questions.
                      </p>
                      <p>
                        What if play was seen as a vivifying force, as an engine
                        for propelling our biggest visions forward, rather than
                        a drain on productivity? How might we adjust our
                        expectations of efficiency?
                      </p>
                      <p>
                        What if our concept of resilience acknowledged human
                        growth is not linear? How then do failure,
                        vulnerability, and radical honesty create spaces for
                        liberation and exploration?
                      </p>
                      <p>
                        What if creative acts are, in fact, acts of necessary
                        resilience? How can they unlock the playground for
                        collective adaptation, re-invention, and re-negotiation?
                      </p>
                      <p>
                        In this upcoming issue of Athena Jones we explore these
                        and other questions to reimagine resilience and joy for
                        our businesses, our world, and ourselves.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={styles.articles}
              id="articles"
              style={{
                marginTop: 0,
              }}
              ref={articlesRef}
            >
              <img
                className={styles.moreArticleImageMobile}
                src={MobileImg}
                alt=""
              />

              {articles.length > 0
                ? articles.map((article, index) => {
                    let articleImg = articleImages[index]
                      ? articleImages[index]
                      : articleImages[0]

                    let articleSp = articleShapes[index]
                      ? articleShapes[index]
                      : articleShapes[0]

                    let count = index

                    let status = article.status
                    let LinkStatus = status === "live" ? article.uri : "athena"

                    return (
                      <>
                        {count < 6 && (
                          <Link
                            key={article.id}
                            to={`/${LinkStatus}`}
                            className={`${styles.article} ${
                              status === "live" ? styles.live : ""
                            }`}
                            id={`article-${count}`}
                          >
                            <article
                              key={`home-article-${article.id}`}
                              className={`${styles.articleContent}`}
                            >
                              <h3
                                className={`${styles.articleContentTextHeading} italic`}
                              >
                                <span>{article.title}</span>
                                <br />
                              </h3>
                              {status === "live" && (
                                <div className={styles.articleArrow}>
                                  <span className="athena-subhead">
                                    Read article
                                  </span>
                                  <Arrow />
                                </div>
                              )}
                              {status === "disabled" && (
                                <div className={styles.articleNoArrow}>
                                  <span className="athena-subhead">
                                    Coming Soon
                                  </span>
                                </div>
                              )}
                            </article>
                            <img
                              src={articleSp}
                              className={`${styles.shape}`}
                              alt="Shape"
                            />
                            <img
                              src={articleImg}
                              className={`${styles.articleImage}`}
                              alt="ArticleImmage"
                            />
                          </Link>
                        )}
                      </>
                    )
                  })
                : null}
              <div className={styles.moreArticleImageMobileOverlay}>
                <MobileImgSVG width="100%" height="100%" />
              </div>
            </div>

            <div
              className={`${styles.moreArticleFeaturedContainer}`}
              style={{
                transition: "all 0.4s",
                backgroundImage: `url(${endlesssBkg})`,
              }}
            >
              <img
                src={endlesssShape}
                alt=""
                style={{
                  transition: "0.4s",
                  backgroundColor: hoverFeatured ? "transparent" : "white",
                }}
              />
              <Link
                to="/athena/articles/joyfulresilienceproject/"
                className={`${styles.endlesssTitle}`}
                onMouseEnter={() => setHoverFeatured(true)}
                onMouseLeave={() => setHoverFeatured(false)}
              >
                <h3
                  style={{
                    color: hoverFeatured ? "white" : "black",
                    transition: "0.3s",
                  }}
                >
                  The Joyful Resilience Project
                </h3>
                <div className={styles.articleArrow}>
                  <span
                    className="athena-subhead"
                    style={{
                      color: hoverFeatured ? "white" : "black",
                      transition: "0.3s",
                    }}
                  >
                    Let's Play
                  </span>
                  <Arrow />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <PreFooter />
    </Layout>
  )
}

export default AthenaPage

export const query = graphql`
  query {
    craft {
      issues: categories(group: "athenaIssues") {
        id
      }
      page: entry(section: "athena") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athena_athena_Entry {
          featuredIssue {
            id
            title
            ... on Craft_athenaIssues_Category {
              issueSubtitle
            }
          }
        }
      }
      articles: entries(
        section: "athenaArticles"
        status: ["live", "disabled"]
      ) {
        id
        title
        uri
        status
        ... on Craft_athenaArticles_athenaArticleTemplated_Entry {
          snippet
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_1x1 {
                url
              }
              ratio_4x3 {
                url
              }
              ratio_3x4 {
                url
              }
              ratio_16x9 {
                url
              }
            }
          }
          articleSubTitle
          issue {
            id
          }
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          snippet
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_1x1 {
                url
              }
              ratio_4x3 {
                url
              }
              ratio_3x4 {
                url
              }
              ratio_16x9 {
                url
              }
            }
          }
          articleSubTitle
          issue {
            id
          }
        }
      }
    }
  }
`
